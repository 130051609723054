import Vue from "vue";

// axios
import axios from "axios";

const axiosIns = axios.create({
  baseURL: "https://apigateway.multicedi.com/api/v1",
  timeout: 3000,
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
