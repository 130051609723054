import Vue from "vue";
import VueRouter from "vue-router";

import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  isAdminLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "login" } },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login-v2.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/passwordlostverify',
      name: 'password_verify',
      component: () => import('@/views/PasswordRecoveryVerify.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      }
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/Dashboard.vue"),
    },
    {
      path: "/user_register",
      name: "user-register",
      component: () => import("@/views/UserRegister.vue"),
    },
    {
      path: "/customer_search",
      name: "customer-search",
      component: () => import("@/views/CustomerSearch.vue"),
    },
    {
      path: "/customer_search/results",
      name: "customer_search_results",
      component: () => import("@/views/CustomerSearchResults.vue"),
    },
    {
      path: "/customer/:id",
      name: "customer_data",
      component: () => import("@/views/CustomerData.vue"),
    },
    // {
    //   path: "/dematerialize",
    //   name: "dematerialize",
    //   component: () => import("@/views/Dematerialize.vue"),
    // },
    // {
    //   path: "/ticket/open",
    //   name: "open_ticket",
    //   component: () => import("@/views/OpenTicket.vue"),
    // },
    // {
    //   path: "/ticket",
    //   name: "user_ticket",
    //   component: () => import("@/views/UserTickets.vue"),
    // },
    {
      path: "/backend",
      name: "backend",
      redirect: "/backend/login",
      meta: {
        layout: "full",
        backend: true,
        resource: "Auth",
      },
    },
    {
      path: "/backend/login",
      name: "backend_login",
      component: () => import("@/views/backend/Login.vue"),
      meta: {
        layout: "full",
        backend: true,
        resource: "Auth",
      },
    },
    {
      path: "/backend/dashboard",
      name: "backend_dashboard",
      component: () => import("@/views/backend/Dashboard.vue"),
      meta: {
        layout: "vertical",
        backend: true,
      },
    },
    {
      path: "/backend/salepoint/register",
      name: "backend_salepoint_register",
      component: () => import("@/views/backend/SalePointRegister.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "/backend/salepoint/list",
      name: "backend_salepoint_list",
      component: () => import("@/views/backend/SalesPointList.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  
  if (!to.meta.backend) {

    if (!canNavigate(to)) {
      if (!isLoggedIn) return next({ name: "login" });
    }
  } else {
    const isLoggedIn = isAdminLoggedIn();

    if (!canNavigate(to)) {
      if (!isLoggedIn) return next({ name: "backend_login" });
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({name: "dashboard"});
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
