import AxiosIns from "@/libs/axios";

export class Requests {
  //Login

  static async login(data) {
    const response = await AxiosIns.post("/login", data);
    return response.data[0];
  }

  //Recupero Password

  static async passwordRecovery(data) {
    const response = await AxiosIns.post("/lost_password", data);
    return response.data[0];
  }

  //Password Recovery Verify

  static async passwordRecoveryVerify(data) {
    const response = await AxiosIns.post('/lost_password_verify', data)
    return response.data[0];
  }

  //Dati Utente in Sessione

  static async getUser() {
    const response = await AxiosIns.get("/me", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    });
    return response.data[0];
  }

  //Registrazione Cliente

  static async customerRegister(data) {
    const response = await AxiosIns.post("/customer_addnew", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    });
    return response.data[0];
  }

  //Verifica OTP

  static async verifyOtp(data) {
    const response = await AxiosIns.post("/otp_verify", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    });
    return response.data[0];
  }

  //Ricerca Cliente

  static async customerSearch(query) {
    const response = await AxiosIns.get("/customer_search?" + query, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    });
    return response.data;
  }

  // Dettagli Cliente

  static async customerDetails(query) {
    const response = await AxiosIns.get("/customer_details?" + query, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    });
    return response.data[0];
  }

  /* -- BACKEND -- */

  //Admin Login

  static async adminLogin(data) {
    const response = await AxiosIns.post("/admin_login", data);
    return response.data[0];
  }

  //Registrazione punto vendita

  static async salePointRegister(data) {
    const response = await AxiosIns.post("/register", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin_access_token"),
      },
    });
    return response.data[0];
  }

  //Lista dei punti vendita

  static async getSalesPointList() {
    const response = await AxiosIns.post("/account_list", null, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin_access_token"),
      },
    });
    return response.data;
  }
}
